<template>
  <div class="page-header-logo">
    <!-- <img class="logo-pic" :src="logoPic" /> -->
  </div>
</template>

<script>
  import i18n from '@/language'
  export default {
    name: 'page-header-logo',
    computed: {
      logoPic() {
        let logo
        if (i18n.locale === 'zh_CN') {
          logo = require('@/assets/images/icp/wb_logo_cn.webp')
        } else {
          logo = require('@/assets/images/icp/wb_logo_hk.webp')
        }
        return logo
      }
    }
  }
</script>

<style lang="scss" scoped>
  .page-header-logo {
    width: 200px;
    height: 32px;

    .logo-pic {
      width: 100%;
      height: 100%;
    }
  }

  @media (min-width: 750px) and (max-width: 1024px) {
    .page-header-logo {
      width: 162.5px;
      height: 26px;
    }
  }

  @media screen and (max-width: 750px) {
    .page-header-logo {
      width: 125px;
      height: 20px;
    }
  }
</style>
